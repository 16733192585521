.Login-section-start {
  padding-top: 1.5em;
}

.Login-width {
  max-width: 768px;
}

.Login-page {
  margin-bottom: 3em;
}

.fieldsetpad {
  padding-bottom: 0.7em;
}

.button-red {
  background: rgb(202, 60, 60);
}

.registerauth input[type="text"] {
  font-family: sans-serif;
  font-size: 90%;
}

.config-panel {
  padding-top: 1em;
}

.login-form legend {
  margin-bottom: 1.5em;
  text-align: center;
}

.login-text {
  vertical-align: middle;
  padding: 0.5em 0;
}

.custom-input {
  border: 0;
  outline: 0;
  font-family: sans-serif;
  font-size: 90%;
  background: transparent;
  border-bottom: 1px solid black;
  width: 100%;
}
