@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=f9bcf146-43ed-49d2-91f7-f3d4ae2afffe&fontids=1476008");

@font-face {
    font-family:"Univers LT W04_57 Conde1476008";
    src:url("Fonts/1476008/c2f0c2bf-1dcc-4ffb-b3ef-6b1770266b7c.woff2") format("woff2"),url("Fonts/1476008/47390e50-3a96-466b-a33c-8eb7eacb0708.woff") format("woff");
    font-style: normal;
}

body {
  font-family: "Univers LT W04_57 Conde1476008";
  font-size: 120%;
  color: #333333;
}

a {
  color: #0078e7;
}

.mandatory {
  color: rgb(230, 40, 40);
}

.errorMessage {
  color: rgb(202, 60, 60);
}

.invalidField {
  background: rgb(255, 179, 179);
}

.deleted {
  text-decoration: line-through;
}

.section-start {
  padding-top: 1.5em;
}

.lineonly input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
}

.backbutton {
  padding-top: 3em;
  padding-bottom: 2em;
}

.button-secondary {
  border-radius: 6px;
  background: rgb(66, 184, 221); /* this is a light blue */
}

.center {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
}

.logo-header {
  margin-top: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: center;
  border-top: 1px solid #999;
  overflow: auto;
}


@media (min-width: 32em) {
  .logo-header img {
    margin-left: auto;
    margin-right: auto;
    max-width: 28em;
  }
  
  .logo-div {
    width:80%;
    float:left;
    margin-left:10%;
  }
  
  .lang-switch {
    float: left;
    width: 10%;
  }
}

.lang-switch {
  margin-top: 0.2em;
}

.lang-switch button {
  padding: 0.2em 0;
  width: 2.5em;
  border: 2px solid #666;
  background-color: transparent;
  
}

.table-button {
  background-color: transparent;
  border: none;
  padding: 0;
}

/* ACCORDION (mobile) */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 1em 1em 1em 0.5em;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 1em;
  transition: 0.4s;
}

.active, .accordion:hover {
  background-color: #ccc;
}

.accordion:after {
  content: '\002B';
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 0.3em;
}

.active:after {
  content: "\2212";
}

.accordionicon {
  margin-top: 0;
  margin-bottom: -0.15em;
  margin-right: 0.4em;
  max-height: 1em;
  min-width: 1em;
  max-width: 1em;
}

.panel {
  padding: 0 0.1em;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  text-align: left;
}

/*Aligned form*/
.form-aligned {
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.form-aligned-row {
  display: table-row;
  height: 1.25em;
}

.form-aligned-left {
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  padding-bottom: .7em;
  vertical-align: middle;
  hyphens: auto;
}

.form-aligned-left label {
  margin: 0;
}

.form-aligned-right {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  padding-bottom: .7em;
}

.form-aligned-right input[type="text"], .form-aligned-right input[type="password"], .form-aligned-right select  {
  font-family: sans-serif;
  font-size: 90%;
  max-width: 60vw;
}

/*Radio*/
.radio-toolbar {
  margin: 10px;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  background-color: #eee;
  padding: 0.6em 1.2em;
  font-size: 90%;
  border: 3px solid #fff;
  border-radius: 10px;
  margin-right: 0.4em;
}

.radio-toolbar label:hover {
  background-color: #0cf;
}

.radio-toolbar input[type="radio"]:focus + label {
  border: 3px solid #444;
}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: #0cf;
  border-color: #000;
}

@media (max-width: 29.999em) {
  .form-aligned-left {
    padding-right: 0.4em;
    max-width: 5em;
  }

  .radio-toolbar label {
     margin-right: 0.3em;
  }
}

.addclinic {
  text-align: center;
  margin: 0.5em auto 2em auto;
}

.addclinic button {
  padding: 0.8em 2em;
  /*display: inline-block;
  background: #fff;
  border: 1px solid #ddd;*/
}

.addclinic button img {
  display: inline-block;
  width: 1em;
  max-height: 1em;
  margin-bottom: -0.15em;
  margin-right: 0.5em;
}

.confirm-box input[type="checkbox"] {
  margin-right: 0.5em;
  margin-bottom: 0.2em;
}

.loading img {
  margin: 1em auto;
}

/* Request */
.request-textfield {
  font-family: sans-serif;
  font-size: 90%;
  max-width: 50vw;
  resize: none;
}

.justify {
  text-align: justify;
}

.privacy {
  margin: 1em auto;
  padding: 0 1em;
  max-width: 60em;
}

.privacy p {
  text-align: justify;
}