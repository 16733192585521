/* START MENU: https://purecss.io/layouts/tucked-menu-vertical/ */
.custom-wrapper {
  margin-bottom: 1em;
  -webkit-font-smoothing: antialiased;
  height: 2.1em;
  overflow: hidden;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  transition: height 0.5s;
}

.custom-wrapper.open {
  height: 17em;
}

.custom-menu-3 {
  text-align: right;
}

.custom-toggle {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  background-color: transparent;
  border: none;
}

.custom-toggle .bar {
  background-color: #777;
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 100px;
  position: absolute;
  top: 18px;
  right: 7px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.custom-toggle .bar:first-child {
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  transform: translateY(-6px);
}

.custom-toggle.x .bar {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-toggle.x .bar:first-child {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.custom-float {
  float: left;
  width: auto;
}

.custom-brand {
  padding-top: 0.1em;
  padding-bottom: 0.15em;
}

.custom-brand img {
  height:1.7em;
  width: 8.5em;
  display: block;
}

.link-color {
  color: #555;
}

@media (max-width: 79.999em) {

  .left-menu {
    background-color: #FDFDFD;
    width: 100%;
  }

  .custom-float {
    width: 100%;
  }

  .custom-menu-3 {
    background-color: #FDFDFD;
    text-align: left;
  }

  .custom-toggle {
    display: block;
  }

  .link-button {
    text-align: left;
    width: 100%;
  }
}

.link-button {
  background-color: transparent;
  border: none;
}

.menuicon {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.5em;
  max-width: 0.8em;
  max-height: 0.8em;
}

/*END MENU*/

/*START TABLE*/

.data {
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5em;
}

.data-table {
  display: table-row;
}

.data-accordion {
  width: 100%;
}
/*Switch*/
@media only screen and (min-width: 80em) {
  .data {
    width: 65em;
  }
  .client-table {
    width: 65em;
  }
}

@media only screen and (max-width: 80em) {
  .data {
    width: 96vw;
  }

  .client-table {
    width: 96vw;
  }
}

@media only screen and (max-width: 54em) {
  .data-table {
    display: none;
  }

  .data {
    width: 100%;
  }
}

@media only screen and (min-width: 54em) {
  .data-accordion {
    display: none;
  }
}

@media (max-width: 30em) {
  .data-accordion {
    font-size: 4vw;
  }
}

/* ACCORDION (mobile) */
.panel-table {
  display: table;
  padding: 0.4em 0.2em;
  width: 100%;
}

.panel-row {
  display: table-row;
}

.panel-left {
  display: table-cell;
  margin-left: 0.2em;
  width: 7em;
  padding: 0.1em;
  vertical-align: top;
}

.panel-right {
  display: table-cell;
  padding: 0.1em;
}

.tableAction {
  width: 100%;
  text-align: center;
  margin-top: 0.5em; 
  margin-bottom: 1.2em;
}

.tableAction button {
  display: inline-block;
  background: #fff;
  border: 1px solid #ddd;
  margin: 0 1.21em;
}

.tableAction button img {
  width: 1em;
  height: 1em;
  margin-bottom: -0.15em;
  margin-right: 0.2em;
}


/* ACCORDION END */

.client-table thead {
  font-size: 90%;
}

.client-table tbody {
  font-size: 85%;
}

.client-table th {
  text-align: center;
  vertical-align: middle;
}

.client-table th, .client-table td {
  padding-left: 0.4em;
  padding-right: 0.4em;
}

.table-comment {
  text-align: left;
  width: 20em;
}

.tableicon {
  margin-top: auto;
  margin-bottom: auto;
  /*margin-right: 0.5em;*/
  max-height: 1em;
  min-width: 1em;
  max-width: 1em;
}

.comment-field {
  width: 95%;
  resize: vertical;
}

/*END TABLE*/

.table-controls {
  display: table-row;
}

.table-yearfilter {
  float: left;
  padding-top: 0.2em;
}

.table-yearfilter select {
  border: none;
  padding: 0.2em 0.4em;
  background-color: #DDD;
  width: 4.5em;  
  text-align: center;
}

.table-search {
  float: right;
  height: 1.8em;
  border: 0.1em #555 solid;
  border-radius: 1em;
  margin-bottom: 0.4em;
}

.table-search input[type="text"] {
  background: url(./search.svg) no-repeat scroll 0.5em 0.3em;
  background-size: 1.3em 1.3em;
  padding: 0.1em 0.7em 0 2.5em;
  border: none;
  height: 1.8em;
  font-family: sans-serif;
  font-size: 90%;
  width: 14em;
}

.table-search button {
  border: none;
  padding: 0;
  margin-left: -1.6em;
  margin-right: 0.5em;
  height: 1.1em;
  width: 1.1em;
}

.table-search button img {
  margin-bottom: -0.16em;
}

.table-buttons {
  float: left;
  text-align: left;
  padding-top: .5em;
}

.table-buttons button {
  width: 2em;
  border: 0;
  background-color: #DDD;
  padding: 0.5em 0.6em 0.4em 0.6em;
  margin-right: 0.4em;
}

.table-buttons button img {
  filter: invert(20%);
  height: 0.8em;
  max-width: 0.8em;
}

.table-buttons button:disabled img {
  filter: invert(50%);
}

.table-page {
  float: left;
  margin-top: 0.5em;
  margin-left: 0.5em;
  padding-top: 0.4em;
  vertical-align: middle;
}

@media (max-width: 32em) {
  .table-yearfilter {
    padding-top: 0.05em;
  }

  .table-search input[type="text"] {
    background-position: 0.6em 0.5em;
    width: 30vw;
    height: 2em;
    font-size: 80%;
  }
}

.contact-link {
  background: none;
  border: none;
  padding: 0;
  color: #0078e7;
  text-decoration: underline;
}

.configpage {
  max-width: 768px;
  margin-bottom: 3em;
  margin-left: auto;
  margin-right: auto;
}

.ordertable {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.ordertable td {
  padding: 0.2em 0.5em;
}

.order-comment {
  font-family: sans-serif;
  font-size: 90%;
  max-width: 50vw;
  resize: none;
}

.footnote {
  font-size: 80%;
}

.news {
  width: 100%;
  max-width: 30em;
  margin: 0 auto;
}

.news .panel {
  background: #f9f9f9;
}

@media (max-width: 30em) {
  .news {
    font-size: 4vw;
  }
}

.newstext {
  padding: 0.5em;
  white-space: pre-wrap;
}

.modal {
  text-align: center;
  padding: 1em;
}

.modal button {
  margin-top: 1.5em;
}
